<script>
import iconsData from './icons.data'

export default {
  name: 'UiIcon',

  props: {
    name: {
      type: String,
      default: 'heart'
    },

    size: {
      type: String,
      default: null,
      validator: v => ['sm', 'md', 'lg'].indexOf(v) !== -1
    },

    color: {
      type: String,
      default: null
    }
  },

  render (createElement) {
    return createElement('i', {
      domProps: {
        innerHTML: `<svg viewbox="0 0 24 24">
  <path d="${iconsData[this.name]}" />
</svg>`
      },
      class: [
        'ui-icon',
        {
          [`ui-icon-${this.size}`]: this.size
        },
        `ui-icon-${this.name}`
      ],
      style: [{
        fill: this.color
      }]
    })
  }
}
</script>

<style lang="scss">
@import '../style/main';
@import '../style/animations';

.ui-icon {
  user-select: none;

  display: inline-block;
  line-height: 24px;
  vertical-align: middle;

  font-size: 16px;
  width: 24px;
  height: 24px;

  &.ui-icon-md {
    width: 18px;
    height: 18px;
  }

  &.ui-icon-sm {
    width: 16px;
    height: 16px;
  }

  &.ui-icon-lg {
    width: 32px;
    height: 32px;
  }

  &.ui-icon-spin svg {
    animation: spin 2s infinite linear;
  }
}
</style>
