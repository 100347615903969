const stopScrollPropagation = {
  mounted () {
    const events = ['scroll', 'wheel', 'touchstart', 'touchmove', 'touchend']
    events.map(v => this.$el.addEventListener(v, this.stopScrollEvents))
  },

  beforeDestroy () {
    const events = ['scroll', 'wheel', 'touchstart', 'touchmove', 'touchend']
    events.map(v => this.$el.removeEventListener(v, this.stopScrollEvents))
  },

  methods: {
    stopScrollEvents (event) {
      event.stopPropagation()
    }
  }
}

export default stopScrollPropagation
