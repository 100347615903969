// mixin: closable

// TODO add a closed state by default to false
import UiIconButton from '@/components/ui/UiIconButton'

export default {
  components: {
    CloseButton: {
      name: 'CloseButton',

      components: {
        UiIconButton
      },

      render (createElement) {
        const attrs = {
          class: 'mx-closable-close-button',
          style: {
            textAlign: 'right'
          }
        }
        const buttonAttrs = {
          props: {
            icon: 'close'
          },
          on: {
            click: () => {
              this.$parent.close()
            }
          }
        }

        if (this.$parent.canClose) {
          return createElement('div', attrs, [
            createElement(UiIconButton, buttonAttrs)
          ])
        } else {
          return null
        }
      }
    }
  },

  props: {
    canClose: {
      type: Boolean,
      default: true
    },

    isOpen: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      closed: !this.isOpen
    }
  },

  watch: {
    isOpen (nextValue, prevValue) {
      const {
        open,
        close
      } = this

      if (nextValue !== prevValue && nextValue) {
        open()
      } else {
        close()
      }
    }
  },

  methods: {
    open () {
      this.$emit('open')
      this.closed = false
    },

    close () {
      this.$emit('close')
      this.closed = true
    }
  }
}
