<script>
import { mapState } from 'vuex'
import { auth } from '@/firebase'
import { ApiErrorHandler } from '@/api'
import { getTwitchAuth, getTwitchDeconnect } from '@/api/twitch'

export default {
  name: 'TwitchConnect',

  data () {
    return {
      url: null,
      loading: false
    }
  },

  computed: mapState({
    isLogged: s => (s.user.uid !== null)
  }),

  methods: {
    async connect () {
      this.loading = true
      try {
        const res = await getTwitchAuth(this.$route.query.admin)

        if (res.data.url) {
          window.location = res.data.url
        }
      } catch (error) {
        ApiErrorHandler(error)
      }

      this.loading = false
    },

    async deconnect () {
      this.loading = true
      if (auth.currentUser) {
        try {
          await getTwitchDeconnect()
        } catch (error) {
          ApiErrorHandler(error)
        }

        auth.signOut()
        this.$router.push('/')
      }
      this.loading = false
    }
  }
}
</script>

<template>
  <div>
    <UiButton
      :loading="loading"
      type="primary"
      :icon="loading ? 'loading' : 'login-variant'"
      @click="isLogged ? deconnect() : connect()"
    >
      {{ isLogged ? 'Déconnexion' : 'Connexion' }}
    </UiButton>
  </div>
</template>
