import { auth } from '../firebase'
import api, { ApiErrorHandler } from './index'

async function getConfig () {
  const token = await auth.currentUser.getIdToken(true)
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

const getTwitchAuth = async function (isAdmin) {
  let res = null

  try {
    res = await api.get(`/twitch/auth/authorize${isAdmin ? '?isAdmin=true' : ''}`)
  } catch (e) {
    ApiErrorHandler(e.message)
  }

  return res.data
}

const getTwitchTokenWithCode = async function (code = null) {
  let res = null
  try {
    res = await api.get(`/twitch/auth/token?code=${code}`)
  } catch (e) {
    ApiErrorHandler(e.message)
  }

  return res.data
}

const getTwitchDeconnect = async function () {
  const config = await getConfig()
  let res = null

  try {
    res = await api.post('/twitch/auth/disconnect', {}, config)
  } catch (e) {
    ApiErrorHandler(e.message)
  }

  return res.data
}

export {
  getTwitchAuth,
  getTwitchDeconnect,
  getTwitchTokenWithCode
}
