import { clanConverter } from '@/firebase/models/ClanModel'
import { db } from '@/firebase'

import {
  PLATFORME_LOADING,
  CLANS_UPDATE,
  CLANS_RESET,
  CLANS_SUBSCRIBE,
  CLANS_UNSUBSCRIBE,
  CLANS_LOADING
} from '../mutation-types'

let firestoreUnsubRef = null

// initial state
const state = {
  loading: true,
  loadingClans: false,
  clans: [],
  clansSubs: 0
}

// getters
const getters = {
  getClanById: (state) => (id) => {
    return state.clans.find(clan => clan.id === id)
  }
}

// actions
const actions = {
  setLoading ({ commit }) {
    commit(PLATFORME_LOADING, true)
  },

  setLoaded ({ commit }) {
    commit(PLATFORME_LOADING, false)
  },

  subClans ({ dispatch, commit, state }) {
    commit(CLANS_SUBSCRIBE)

    if (state.clansSubs > 0) {
      dispatch('getSubscribedClans')
    }
  },

  unsubClans ({ dispatch, commit, state }) {
    commit(CLANS_UNSUBSCRIBE)

    if (state.clansSubs === 0) {
      dispatch('unsubscribeFromClans')
    }
  },

  async getClans ({ commit }) {
    commit(CLANS_LOADING, true)

    try {
      const clans = []
      const docsRef = db.collection('clans')
        .withConverter(clanConverter)

      const query = await docsRef.get()
      query.forEach(doc => {
        clans.push(doc.data())
      })

      commit(CLANS_UPDATE, clans)
    } catch (e) {
      console.debug(e)
    }

    commit(CLANS_LOADING, false)
  },

  resetClans ({ commit }) {
    commit(CLANS_RESET)
  },

  async getSubscribedClans ({ commit }) {
    try {
      const docsRef = db.collection('clans')
        .withConverter(clanConverter)

      firestoreUnsubRef = await docsRef.onSnapshot(query => {
        const clans = []

        query.forEach(doc => {
          clans.push(doc.data())
        })

        commit(CLANS_UPDATE, clans)
      })
    } catch (e) {
      console.debug(e)
    }
  },

  unsubscribeFromClans ({ commit }) {
    try {
      if (firestoreUnsubRef) {
        firestoreUnsubRef()
      }
    } catch (e) {
      console.debug(e)
    }
  }
}

// mutations
const mutations = {
  [PLATFORME_LOADING] (state, isLoading) {
    state.loading = isLoading
  },

  [CLANS_LOADING] (state, isLoading) {
    state.loadingClans = isLoading
  },

  [CLANS_SUBSCRIBE] (state) {
    state.clansSubs = state.clansSubs + 1
  },

  [CLANS_UNSUBSCRIBE] (state) {
    state.clansSubs = state.clansSubs - 1
  },

  [CLANS_UNSUBSCRIBE] (state) {
    state.clansSubs = state.clansSubs - 1
  },

  [CLANS_UPDATE] (state, clans) {
    state.clans = clans
  },

  [CLANS_RESET] (state) {
    state.clans = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
