<script>
import anime from 'animejs'

import UiButton from '../UiButton'
import UiIconButton from '../UiIconButton'
import UiIcon from '../UiIcon'

/**
 * An awesome Snackbar (Better than Toast)
 * @displayName UiSnackBar
 */
export default {
  name: 'UiSnackBar',

  components: {
    UiButton,
    UiIcon,
    UiIconButton
  },

  props: {
    /**
     * The duration of the snackbar
     */
    duration: {
      type: Number,
      default: 3500
    },

    /**
     * The content of the snackbar
     */
    message: {
      type: String,
      required: true
    },

    /**
     * The type of the snackbar
     * `success,alert,info,warn,default`
     */
    type: {
      type: String,
      default: 'default',
      validator: v => [
        'success',
        'alert',
        'info',
        'warn',
        'default'
      ].indexOf(v) !== -1
    },

    /**
     * The name of the icon who is show on the right of the snackbar
     */
    icon: {
      type: String,
      default: null
    },

    /**
     * the action when the user click on the Undo option
     */
    undoAction: {
      type: Function,
      default: () => {}
    },

    /**
     * The text in the title of the Undo button
     */
    undoText: {
      type: String,
      default: ''
    },

    /**
     * If we show the retry button
     */
    isRetry: Boolean,

    /**
     * A function called when the snackbar is mounted
     */
    onStart: {
      type: Function,
      default: () => {}
    },

    /**
     * A function called when the the user click on Retry
     */
    onRetry: {
      type: Function,
      default: () => {}
    },

    /**
     * A function called when the snackbar duration is finish
     */
    onFinish: {
      type: Function,
      default: () => {}
    },

    /**
     * The position of the snackbar on the screen
     * `top right, top left, bottom right, bottom, left`
     */
    position: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      timer: null
    }
  },

  computed: {
    actionLabel () {
      return 'Retry'
    }
  },

  mounted () {
    this.onStart()

    if (this.duration) {
      this.timer = setTimeout(this.finish, this.duration)
    }

    anime({
      targets: this.$el,
      opacity: [0, 1],
      translateX: [-20, 0],
      duration: 1700
    })
  },

  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },

  methods: {
    finish () {
      this.timer = null
      this.onFinish()
      this.$nextTick(() => { this.destroySnack() })
    },

    undo () {
      this.undoAction()
      this.finish()
    },

    destroySnack () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.$emit('destroy')
    }
  }
}
</script>

<template>
  <div
    class="ui-snackbar"
    :class="[position, type]"
  >
    <UiIcon
      v-if="icon"
      :name="icon"
    />

    <div class="ui-snackbar__content">
      <span v-emoji="message" />
    </div>

    <div class="box-spacing" />

    <UiButton v-if="isRetry">
      {{ actionLabel }}
    </UiButton>

    <UiIconButton
      v-if="undoAction"
      icon="reload"
      :title="undoText"
      light
      @click="undo"
    />

    <UiIconButton
      icon="close"
      light
      @click="destroySnack"
    />
  </div>
</template>

<style lang="scss">
@import '../style/main';
@import '../style/transitions';

.ui-snackbar {
  position: fixed;
  min-width: 150px;
  max-width: 80%;
  min-height: 42px;
  line-height: 42px;
  z-index: 999;
  overflow: visible;

  background-color: $blue-grey-700;
  color: $white;
  border-radius: 5px;

  &__content {
    overflow: hidden;
    @include text-ellipsis();
  }

  padding: $gutter-sm;

  @include card(1);

  display: flex;
  flex-direction: row;

  &.bottom {
    bottom: 0;
    margin-bottom: $gutter;
  }
  &.center {
    margin: 0 auto;
    margin-left: -225px;
    left: 50%;
  }
  &.top {
    top: 0;
    margin-top: $gutter;
  }
  &.left {
    left: 0;
    margin-left: $gutter;
  }
  &.right {
    right: 0;
    margin-right: $gutter;
  }

  &.info {
    background-color: $info-color;
    color: $white;
  }
  &.success {
    background-color: $success-color;
    color: $white;
  }
  &.warn {
    background-color: $warning-color;
    color: $white;
  }
  &.alert {
    background-color: $error-color;
    color: $white;
  }
}
</style>

<docs>
How to call a SnackBar programaticaly
```
new Vue({
  mounted () {
    this.$snack.push({
      duration: 3500,
      message: '',
      type: 'default',
      isRetry: false,
      onStart () {},
      onRetry () {},
      onFinish () {},
      position: 'bottom right'
    })
  }
})
```

```
new Vue({
  template: `<UiButton type="primary" @click="$snack.push({ message: 'toast' })">Contenu</UiButton>`
})
```
</docs>
