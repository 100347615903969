<script>
import { mapState } from 'vuex'
import NavigationPanel from './NavigationPanel'
import TwitchConnect from '@/components/TwitchConnect'

export default {
  name: 'Navigation',

  components: {
    NavigationPanel,
    TwitchConnect
  },

  data () {
    return {
      menuIsOpen: false
    }
  },

  computed: mapState({
    isLogged: s => (s.user.uid !== null)
  }),

  watch: {
    '$route.path' (nextValue, prevValue) {
      if (nextValue !== prevValue) {
        this.menuIsOpen = false
      }
    }
  }
}
</script>

<template>
  <div class="navigation">
    <div class="navigation__navbar">
      <UiIconButton
        icon="menu"
        light
        @click="menuIsOpen = true"
      />

      <div class="box-spacing" />

      <TwitchConnect v-if="!isLogged" />

      <UiMenu
        v-if="isLogged"
        position="right"
      >
        <UiIconButton
          icon="account-circle"
          light
        />

        <template #content="{closeMenu}">
          <UiButton
            :to="{ name: 'platform'}"
            exact
            @click.native="closeMenu"
          >
            Headquarter
          </UiButton>

          <TwitchConnect />
        </template>
      </UiMenu>
    </div>

    <NavigationPanel
      v-show="menuIsOpen"
      @close="menuIsOpen = false"
    />
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.navigation {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &__navbar {
    position: relative;
    min-height: 4em;
    padding: 0 $gutter;
    z-index: 100;
    background-color: $dark;
    color: $white;
    pointer-events: auto;

    display: flex;
    flex-direction: row;
    align-items: center;

    @include card(1);
  }
}
</style>
