import Model, { converterGenerator } from './Model'

class Profile extends Model {
  username
  photoUrl
  displayName
  clanId
  followedAt
  _followedAt
  isFollow
  isSubscribed
  isBoughtClanAccess
  twitchCreatedAt
  _twitchCreatedAt
  email

  constructor (data = {}) {
    super(data)

    this.email = data.email || null
    this.username = data.username || null
    this.displayName = data.displayName || null
    this.photoUrl = data.photoUrl || null
    this.clanId = data.clanId || null
    this._followedAt = data.followedAt || null
    this.followedAt = this._followedAt && this._followedAt.toDate()
    this.isFollow = data.isFollow || false
    this.isBoughtClanAccess = data.isBoughtClanAccess || false
    this.isSubscribed = data.isSubscribed || false
    this._twitchCreatedAt = data.twitchCreatedAt || null
    this.twitchCreatedAt = this._twitchCreatedAt && this._twitchCreatedAt.toDate()
  }
}

export const profileConverter = converterGenerator(Profile, data => {
  return {
    email: data.email,
    username: data.username,
    avatarUrl: data.photoUrl,
    displayName: data.displayName,
    clanId: data.clanId,
    followedAt: data._followedAt,
    isFollow: data.isFollow,
    isBoughtClanAccess: data.isBoughtClanAccess,
    isSubscribed: data.isSubscribed,
    twitchCreatedAt: data._twitchCreatedAt
  }
})

export default Profile
