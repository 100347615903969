import firebase from '../index'

class Model {
  id
  #_createdAt
  createdAt
  #_updatedAt
  updatedAt

  constructor (data = {}) {
    this.id = data.id || null
    this._createdAt = data.createdAt || null
    this.createdAt = this._createdAt && this._createdAt.toDate()
    this._updatedAt = data.updatedAt || null
    this.updatedAt = this._updatedAt && this._updatedAt.toDate()
  }
}

export function converterGenerator (ParentClass, decorator = () => {}) {
  return {
    toFirestore (data = ParentClass) {
      return {
        createdAt: data._createdAt,
        updatedAt: data._updatedAt,
        ...decorator(data)
      }
    },

    fromFirestore (
      snapshot = firebase.firestore.QueryDocumentSnapshot,
      options = firebase.firestore.SnapshotOptions
    ) {
      const data = snapshot.data(options)

      return new ParentClass({
        id: snapshot.id,
        ...data
      })
    }
  }
}

export default Model
