import {
  mdiHeart,
  mdiLoading,
  mdiContentCopy,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiClose,
  mdiInstagram,
  mdiTwitter,
  mdiTwitch,
  mdiFacebook,
  mdiMagnify,
  mdiCalendarHeart,
  mdiMenu,
  mdiAccountCircle,
  mdiAccount,
  mdiAccountGroup,
  mdiArrowLeft,
  mdiArrowRight,
  mdiStar,
  mdiLoginVariant,
  mdiInformation,
  mdiHandHeart,
  mdiCalendarMultiple,
  mdiHome,
  mdiTriforce,
  mdiDiscord,
  mdiGift,
  mdiBadgeAccount,
  mdiEmail,
  mdiDelete
} from '@mdi/js'

export default {
  heart: mdiHeart,
  loading: mdiLoading,
  'content-copy': mdiContentCopy,
  'eye-off-outline': mdiEyeOffOutline,
  'eye-outline': mdiEyeOutline,
  close: mdiClose,
  instagram: mdiInstagram,
  twitter: mdiTwitter,
  facebook: mdiFacebook,
  twitch: mdiTwitch,
  magnify: mdiMagnify,
  'calendar-heart': mdiCalendarHeart,
  menu: mdiMenu,
  'account-circle': mdiAccountCircle,
  account: mdiAccount,
  'account-group': mdiAccountGroup,
  'arrow-left': mdiArrowLeft,
  'arrow-right': mdiArrowRight,
  'hand-heart': mdiHandHeart,
  'calendar-multiple': mdiCalendarMultiple,
  star: mdiStar,
  'login-variant': mdiLoginVariant,
  information: mdiInformation,
  home: mdiHome,
  triforce: mdiTriforce,
  discord: mdiDiscord,
  gift: mdiGift,
  'badge-account': mdiBadgeAccount,
  email: mdiEmail,
  delete: mdiDelete
}
