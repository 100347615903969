<script>
/**
 * Simple Form component with style
 * @displayName UiForm
 */
export default {
  name: 'UiForm',

  props: {
    inline: Boolean
  },

  render (createElement) {
    return createElement('form', {
      class: [
        'ui-form',
        { inline: this.inline }
      ],
      attrs: {
        novalidate: true
      },
      on: {
        submit (e) {
          e.preventDefault()
          return false
        }
      }
    }, this.$slots.default)
  }
}
</script>

<style lang="scss">
@import '../style/main';

.ui-form {
  display: inherit;
  flex-direction: inherit;

  .ui-input,
  .ui-radio,
  .ui-select,
  .ui-tags {
    margin-bottom: $gutter-sm;
  }

  &.inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .container-errors {
    min-height: 1.4em;
    margin-bottom: $gutter-sm;
    .text-error {
      font-size: 1em;
    }
  }
}
</style>

<docs>
```
<UiForm>
  <UiFormInput
    name="exemple"
    label="Exemple UiForm"
    placeholder="exemple placeholder"
    v-model="exemple"
  />
  <UiButton type="primary">Submit</UiButton>
</UiForm>
```

Inline Form :
```
<UiForm inline>
  <UiFormInput
    name="exemple"
    label="Exemple UiForm"
    placeholder="exemple placeholder"
    v-model="exemple"
    inline
  />
  <UiButton type="primary">Submit</UiButton>
</UiForm>
```
</docs>
