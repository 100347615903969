// UI themed elements list
import UiButton from './UiButton'
import UiDialog from './UiDialog'
import UiForm from './UiForm'
import UiFormField from './UiFormField'
import UiFormInput from './UiFormInput'
import UiHotLink from './UiHotLink'
import UiIcon from './UiIcon'
import UiIconButton from './UiIconButton'
import UiLoader from './UiLoader'
import UiMenu from './UiMenu'
import UiSidePanel from './UiSidePanel'
import UiSnackBar from './UiSnackBar'
import UiExpand from './UiExpand'

const components = [
  UiButton,
  UiDialog,
  UiForm,
  UiFormField,
  UiFormInput,
  UiHotLink,
  UiIcon,
  UiIconButton,
  UiLoader,
  UiMenu,
  UiSidePanel,
  UiSnackBar,
  UiExpand
]

function install (Vue) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

export default {
  install
}
