import Profile, { profileConverter } from '@/firebase/models/ProfileModel'
import { db, auth, FieldValue } from '@/firebase'

import {
  PROFILE_UPDATE,
  PROFILE_SET,
  PROFILE_LOADING,
  IS_ADMIN_UPDATE
} from '../mutation-types'

// initial state
const state = {
  profile: new Profile(),
  isAdmin: false,
  loading: true
}

// getters
const getters = {}

// actions
const actions = {
  async getProfile ({ commit }) {
    const { currentUser } = auth

    if (currentUser) {
      commit(PROFILE_LOADING, true)

      try {
        const profileRef = db.collection('profiles')
          .withConverter(profileConverter)
          .doc(currentUser.uid)
        const profile = await profileRef.get()

        if (profile.exists) {
          commit(PROFILE_SET, profile.data())
        } else {
          commit(PROFILE_SET, null)
        }
      } catch (error) {
        console.error(error.message)
        throw new Error(error)
      }

      commit(PROFILE_LOADING, false)
    }
  },

  async updateProfile ({ commit }, data) {
    const { currentUser } = auth

    if (currentUser) {
      commit(PROFILE_LOADING, true)

      try {
        const ref = db.collection('profiles').doc(currentUser.uid)
        await ref.update({
          ...data,
          updatedAt: FieldValue.serverTimestamp()
        })

        commit(PROFILE_UPDATE, data)
      } catch (error) {
        console.error(error.message)
        throw new Error(error)
      }

      commit(PROFILE_LOADING, false)
    }
  },

  removeProfile ({ commit }) {
    commit(PROFILE_SET, null)
  },

  setIsAdmin ({ commit }, value) {
    commit(IS_ADMIN_UPDATE, value)
  }
}

// mutations
const mutations = {
  [PROFILE_SET] (state, profile) {
    state.profile = profile || new Profile()
  },

  [PROFILE_UPDATE] (state, newProfile) {
    state.profile = {
      ...state.profile,
      ...newProfile
    }
  },

  [IS_ADMIN_UPDATE] (state, value) {
    state.isAdmin = value
  },

  [PROFILE_LOADING] (state, value) {
    state.loading = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
