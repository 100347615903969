import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/storage'

import 'firebase/functions'

import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MSG_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID
} from '@/utils/constants'

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MSG_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(firebaseConfig)

const analytics = firebase.analytics()
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const functions = firebase.functions()

if (process.env.VUE_APP_FIREBASE_AUTH_EMULATOR_PORT) {
  auth.useEmulator(`http://localhost:${process.env.VUE_APP_FIREBASE_AUTH_EMULATOR_PORT}`)
}

if (process.env.VUE_APP_FIRESTORE_EMULATOR_PORT) {
  db.useEmulator('localhost', process.env.VUE_APP_FIRESTORE_EMULATOR_PORT)
}

// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export const { FieldValue, FieldPath } = firebase.firestore

export default firebase

export {
  analytics,
  db,
  auth,
  storage,
  functions
}
