import twemoji from 'twemoji'

function parser (el, binding) {
  el.innerHTML = twemoji.parse(binding.value)
}

const emoji = {
  inserted: parser,
  update: parser
}

export default emoji
