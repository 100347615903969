export const PROFILE_UPDATE = 'USER_PROFILE_UPDATE'
export const PROFILE_SET = 'USER_PROFILE_SET'
export const UID_UPDATE = 'USER_UID_UPDATE'
export const UID_REMOVE = 'USER_UID_REMOVE'
export const IS_ADMIN_UPDATE = 'USER_IS_ADMIN_UPDATE'

export const PLATFORME_LOADING = 'PLATFORME_LOADING_SET'
export const CLANS_UPDATE = 'PLATFORME_CLANS_UPDATE'
export const CLANS_RESET = 'PLATFORME_CLANS_RESET'
export const CLANS_SUBSCRIBE = 'PLATFORME_CLANS_SUBSCRIBE'
export const CLANS_UNSUBSCRIBE = 'PLATFORME_CLANS_UNSUBSCRIBE'
export const CLANS_LOADING = 'PLATFORME_CLANS_LOADING'
export const PROFILE_LOADING = 'PROFILE_LOADING_SET'

export default {
  PROFILE_UPDATE,
  PROFILE_SET,
  UID_UPDATE,
  UID_REMOVE,
  IS_ADMIN_UPDATE,
  PLATFORME_LOADING,
  CLANS_UPDATE,
  CLANS_RESET,
  CLANS_SUBSCRIBE,
  CLANS_UNSUBSCRIBE,
  CLANS_LOADING,
  PROFILE_LOADING
}
