<script>
const getChildrenTextContent = function (children) {
  return children && children.map(function (node) {
    return node.children
      ? getChildrenTextContent(node.children)
      : node.text
  }).join('')
}

/**
 * Secured ans styled Links
 * @displayName UiHotLink
 */

export default {
  name: 'UiHotLink',

  props: {
    to: {
      type: [String, Object],
      default: null
    },

    exact: Boolean,

    href: {
      type: String,
      default: null
    },

    target: {
      type: String,
      default: null
    },

    blank: Boolean,

    unescape: Boolean,

    nostyle: Boolean
  },

  render (createElement) {
    if (this.unescape && this.href) {
      const inner = getChildrenTextContent(this.$slots.default) || this.href
      return createElement('span', {
        domProps: {
          innerHTML: `
            <a
              class="ui-link"
              href="${this.href}"
              ${this.blank ? 'target="_blank"rel="noopener noreferrer"' : ''}
            >
              ${inner}
            </a>
          `
        }
      })
    } else {
      const attrs = {
        class: [
          'ui-link',
          { nostyle: this.nostyle }
        ],
        attrs: {
          href: this.href
        },
        on: this.$listeners
      }

      let tag = 'a'

      if (this.$router && this.to) {
        this.$options.props = {
          ...(
            this.$options.components.RouterLink ||
            this.$options.components['router-link']
          ),
          ...this.$options.props
        }
        tag = 'router-link'
        attrs.props = this.$props
        delete attrs.props.href
        delete attrs.attrs.href
      } else if (this.blank || this.target) {
        attrs.attrs.target = this.target || '_blank'
        attrs.attrs.rel = 'noopener noreferrer'
      }

      const child = this.$slots.default ? [...this.$slots.default] : this.href

      return createElement(tag, attrs, child)
    }
  }
}
</script>

<style lang="scss">
@import '../style/main';

.ui-link:not(.nostyle) {
  text-decoration: none;
  color: $palette-blue;
  padding-bottom: 1px;
  border-bottom: 0;

  line-height: 1.4em;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid $palette-blue;
  }
}
</style>

<docs>
Link secured :
```
<UiHotLink href="http://google.fr" blank>Google.fr</UiHotLink>
```

Link secured unstyled :
```
<UiHotLink href="http://google.fr" nostyle blank>Google.fr</UiHotLink>
```
</docs>
