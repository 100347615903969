export const APP_NAME = 'ChatonDeAru'
export const VERSION = 'v1.5.0'

const env = process.env

export const FIREBASE_API_KEY = env.VUE_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = env.VUE_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_DATABASE_URL = env.VUE_APP_FIREBASE_DATABASE_URL
export const FIREBASE_PROJECT_ID = env.VUE_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = env.VUE_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MSG_SENDER_ID = env.VUE_APP_FIREBASE_MSG_SENDER_ID
export const FIREBASE_APP_ID = env.VUE_APP_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID = env.VUE_APP_FIREBASE_MEASUREMENT_ID

export const API_URL = env.VUE_APP_API_URL
export const TWITCH_MY_CHANNEL_ID = '268866421'

export const RECAPTCHA_KEY = '6LfKh7wUAAAAAHJfIEMX5OOxPJCTtoNXZwKUsO6G'

// REGEX
export const REGEX_USERNAME = /^([A-Za-z0-9]+(_[A-Za-z0-9]+)*){3,38}$/
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/

// Reason: this regex is not mine and I don't know how to avoid using control chars
// eslint-disable-next-line vue/max-len, no-control-regex
export const REGEX_EMAIL = /^(?:(?:(?:(?:[a-zA-Z]|\d|[!#$%&'*+\-/=?^_\x60{|}~]|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])+(?:\.([a-zA-Z]|\d|[!#$%&'*+\-/=?^_\x60{|}~]|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])+)*)|(?:(?:\x22)(?:(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(?:\x20|\x09)+)?(?:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])|(?:\(?:[\x01-\x09\x0b\x0c\x0d-\x7f]|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}]))))*(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(\x20|\x09)+)?(?:\x22)))@(?:(?:(?:[a-zA-Z]|\d|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])|(?:(?:[a-zA-Z]|\d|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])(?:[a-zA-Z]|\d|-|\.|_|~|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])*(?:[a-zA-Z]|\d|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])))\.)+(?:(?:[a-zA-Z]|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])|(?:(?:[a-zA-Z]|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])(?:[a-zA-Z]|\d|-|\.|_|~|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])*(?:[a-zA-Z]|[\x{00A0}\-\x{D7FF}\x{F900}\-\x{FDCF}\x{FDF0}\-\x{FFEF}])))\.?$/

// Platform
export const IS_IOS = navigator.platform &&
                      /iPad|iPhone|iPod/.test(navigator.platform)
export const IS_MOBILE = typeof window.orientation !== 'undefined'
export const IS_TOUCH = ('ontouchstart' in window) ||
                        (navigator.maxTouchPoints > 0) ||
                        (navigator.msMaxTouchPoints > 0)

export const SCREEN_XS = 480
export const SCREEN_SM = 768
export const SCREEN_MD = 992
export const SCREEN_LG = 1200

export const IS_MOBILE_SIZE = window.innerWidth < SCREEN_MD

export default {
  APP_NAME,
  VERSION,
  IS_TOUCH,
  IS_MOBILE,
  IS_IOS,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MSG_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  REGEX_USERNAME,
  REGEX_PASSWORD,
  REGEX_EMAIL,
  API_URL
}
