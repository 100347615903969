import {
  UID_UPDATE,
  UID_REMOVE
} from '../mutation-types'

// initial state
const state = {
  uid: null
}

// getters
const getters = {}

// actions
const actions = {
  updateUid ({ commit }, uid) {
    commit(UID_UPDATE, uid)
  },

  removeUid ({ commit }) {
    commit(UID_REMOVE)
  }
}

// mutations
const mutations = {
  [UID_UPDATE] (state, uid) {
    state.uid = uid
  },

  [UID_REMOVE] (state) {
    state.uid = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
