<i18n>
{
  "en-US": {
    "click_to_copy": "Click to copy the field in your clipboard",
    "clear_input": "Clear content",
    "snack" : {
      "copy_ok": "Successfully copied!",
      "copy_nok": "Not copied",
      "copy_ko": "Oops unable to copy"
    }
  },
  "fr-FR": {
    "click_to_copy": "Cliquer pour copier le contenu du champ dans le presse-papier",
    "clear_input": "Supprimer le contenu",
    "snack" : {
      "copy_ok": "Copié !",
      "copy_nok": "Impossible de copier",
      "copy_ko": "Oops impossible de copier"
    }
  }
}
</i18n>

<script>
import { IS_IOS } from '@/utils/constants'

import UiFormField from '../UiFormField'
import UiFormFieldMixin from '../UiFormField/UiFormFieldMixin'

import UiIcon from '../UiIcon'

const TYPE_PASSWORD = 'password'
const TYPE_TEXTAREA = 'textarea'

function checkCopy () {
  // if (document.execCommand('copy'))
  const check = !!document.queryCommandSupported
  return check && !!document.queryCommandSupported('copy')
}

const CAN_COPY = checkCopy()

/**
 * One Input to gourvern all Inputs (and textarea).
 * @displayName UiFormInput
 */
export default {
  name: 'UiFormInput',

  mixins: [UiFormFieldMixin],

  props: {
    type: {
      type: String,
      default: 'text',
      validator: v => [
        'text',
        TYPE_PASSWORD,
        'color',
        'date',
        'datetime',
        'email',
        'file',
        TYPE_TEXTAREA,
        'number'
      ].indexOf(v) !== -1
    },

    autocomplete: {
      type: String,
      default: 'off'
    },

    debounce: {
      type: Number,
      default: 0
    },

    maxlength: {
      type: Number,
      default: null
    },

    minlength: {
      type: Number,
      default: null
    },

    selectable: Boolean,

    resetable: Boolean,

    copypaste: Boolean,

    hide: Boolean,

    icon: {
      type: String,
      default: null
    },

    // only for textarea
    rows: {
      type: Number,
      default: null
    },

    // only for textarea
    noResize: Boolean,

    // only for number
    min: {
      type: Number,
      default: null
    },

    // only for number
    max: {
      type: Number,
      default: null
    },

    // only for number
    step: {
      type: Number,
      default: null
    }
  },

  data () {
    const hasValue = !!this.value && this.value.length > 0

    return {
      hidden: this.type === TYPE_PASSWORD || (this.hide && hasValue)
    }
  },

  computed: {
    isTextarea () {
      return this.type === TYPE_TEXTAREA
    },
    isPassword () {
      return this.type === TYPE_PASSWORD
    }
  },

  watch: {
    hidden (nextValue) {
      if (!nextValue) {
        this.$emit('visible')
      }
    }
  },

  methods: {
    debounceUpdate (event) {
      const value = event.target.value
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer)
      }

      this.debounceTimer = setTimeout(() => {
        this.update(value)
        this.debounceTimer = null
      }, this.debounce)
    },

    onFocusBlur (eventName = 'focus', event) {
      const $ref = this.$refs.input

      if ($ref && this.selectable) {
        $ref.setSelectionRange(0, this.value.length)
      }

      if (eventName === 'blur' && this.hide && !this.hidden) {
        this.hidden = true
      }

      this.$emit(eventName, event)
    },

    onCopy (event) {
      const $input = this.$refs.input

      if (document.body.createTextRange) {
        // if IE

        const textRange = document.body.createTextRange()
        textRange.moveToElementText($input)
        textRange.select()
        textRange.execCommand('Copy')
      } else if (window.getSelection && document.createRange) {
        // Other

        const oldContentEditable = $input.contentEditable
        const oldReadOnly = $input.readOnly
        const range = document.createRange()

        // IOS does not copy if elelement is not content editable
        $input.contentEditable = true
        // IOS does not copy if form element is read-only
        $input.readOnly = false
        range.selectNodeContents($input)

        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)

        if (IS_IOS) {
          $input.setSelectionRange(0, 999999)
        } else {
          $input.select()
        }

        // reset input after select
        $input.contentEditable = oldContentEditable
        $input.readOnly = oldReadOnly

        if (CAN_COPY) {
          try {
            const success = document.execCommand('copy')
            if (success) {
              this.$snack.push({
                type: 'success',
                message: this.$t && this.$t('snack.copy_ok')
              })
            } else {
              this.$snack.push({
                type: 'alert',
                message: this.$t && this.$t('snack.copy_nok')
              })
            }
          } catch (err) {
            this.$snack.push({
              type: 'alert',
              message: this.$t && this.$t('snack.copy_ko')
            })
          }
        }

        // TODO add snack with message
        // "Press CTRL+C to copy" for non mobile userAgent
        // else {
        //   if (!navigator.userAgent.match(/ipad|ipod|iphone|android|silk/i))
        // }

        window.getSelection().removeAllRanges()
      }
    },

    resetInput () {
      this.update('')
    }
  },

  render (createElement) {
    let $iconRight, $iconLeft
    const {
      value,
      type,
      maxlength,
      minlength,
      autocomplete,
      rows,
      hide,
      hidden,
      isPassword,
      isTextarea,
      resetable,
      resetInput,
      onFocusBlur,
      debounceUpdate,
      onCopy,
      copypaste,
      noResize,
      min,
      max,
      step,
      icon
    } = this
    const tag = (isTextarea && TYPE_TEXTAREA) || 'input'

    let inputType = isTextarea ? null : type

    if (isPassword && hide && !hidden) {
      inputType = 'text'
    }

    const inputAttrs = {
      ...this.attributes,
      type: inputType,
      maxlength,
      minlength,
      autocomplete,
      rows,
      min,
      max,
      step
    }

    if (resetable && !hide) {
      $iconRight = createElement(UiIcon, {
        class: 'ui-field__content-icon-right',
        attrs: {
          name: 'close',
          title: this.$t && this.$t('clear_input')
        },
        nativeOn: {
          click: e => resetInput(e)
        }
      })
    }

    if (hide) {
      $iconRight = createElement(UiIcon, {
        class: 'ui-field__content-icon-right',
        attrs: {
          name: `eye${hidden ? '' : '-off'}-outline`
        },
        nativeOn: {
          click: () => { this.hidden = !hidden }
        }
      })
    }

    if (CAN_COPY && copypaste) {
      $iconLeft = createElement(UiIcon, {
        class: 'ui-field__content-icon-left',
        attrs: {
          name: 'content-copy',
          title: this.$t && this.$t('click_to_copy')
        },
        nativeOn: {
          click: e => onCopy(e)
        }
      })
    }

    if (icon) {
      $iconLeft = createElement(UiIcon, {
        class: 'ui-field__content-icon-left',
        attrs: {
          name: icon
        }
      })
    }

    // create Input
    const $input = createElement('div', {
      class: 'ui-field__content'
    }, [
      createElement(tag, {
        class: {
          hidden: hide && !isPassword && hidden,
          'no-resize': isTextarea && noResize,
          'icon-right': $iconRight,
          'icon-left': $iconLeft
        },
        domProps: {
          value
        },
        attrs: inputAttrs,
        on: {
          focus: e => onFocusBlur('focus', e),
          blur: e => onFocusBlur('blur', e),
          input: e => debounceUpdate(e),
          paste: () => { if (hide) { this.hidden = true } }
        },
        ref: 'input'
      }),
      $iconRight,
      $iconLeft
    ])

    return createElement(UiFormField, { class: 'ui-input' }, [$input])
  }
}
</script>

<docs>
```
<UiForm>
  <UiFormInput
    name="exemple"
    label="Exemple Form"
    placeholder="exemple placeholder"
    v-model="exemple"
    icon="heart"
  />

  <UiFormInput
    name="exemple"
    label="Exemple Form"
    placeholder="exemple placeholder"
    v-model="exemple"
    resetable
  />

  <UiFormInput
    name="exemple"
    label="Exemple Password"
    type="password"
    placeholder="Password"
    v-model="exemple"
    hide
  />

  <UiFormInput
    name="exemple"
    label="Exemple Number"
    placeholder="0"
    type="number"
    v-model="exempleNumber"
  />

  <UiFormInput
    name="exemple"
    label="Exemple Number"
    value="exemple value"
    selectable
    copypaste
  />

  <UiFormInput
    name="exemple"
    label="Exemple Number"
    value="exemple value"
    type="textarea"
  />
</UiForm>
```
</docs>
