<template>
  <div class="notfound">
    <div class="box-container-sm">
      <section>
        <h1>404.</h1>
        <p>
          <UiButton
            to="/"
            light
          >
            Retour à l'Accueil
          </UiButton>
        </p>
        <img
          alt="Miaou"
          src="https://c.tenor.com/NePsZDIhX5wAAAAj/monocle-private-detective.gif"
        >
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',

  metaInfo () {
    const desc = 'La page demandé n\'existe pas sur le site Chatondearu'
    const pageName = 'Erreur 404'

    return {
      title: pageName,
      meta: [
        { vmid: 'prerender-status-code', name: 'prerender-status-code', content: '404' },
        { vmid: 'description', name: 'description', content: desc },
        { vmid: 'og:title', property: 'og:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'og:description', property: 'og:description', content: desc },
        // { vmid: 'og:image', property: 'og:image', content: this.planningPath },
        { vmid: 'twitter:title', name: 'twitter:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'twitter:description', name: 'twitter:description', content: desc }
        // { vmid: 'twitter:image', name: 'twitter:image', content: this.planningPath }
      ],
      link: [
        {
          rel: 'canonical',
          hreflang: 'fr',
          href: 'https://chatondearu.fr/demineur'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/style/variables';

.notfound {
  text-align: center;
  padding: $gutter;
  color: $white;

  h1 {
    font-size: 7em;
  }
}
</style>
