<script>
export default {
  name: 'UiFormField',

  render (createElement) {
    let $label, $help
    const {
      name,
      label,
      help,
      inline,
      light,
      invalid,
      valid,
      required,
      disabled,
      $slots
    } = this.$parent

    // create help
    if ($slots.help || help) {
      $help = createElement('div', {
        class: 'ui-field__help'
      }, $slots.help || help)
    }

    // create Label
    if ($slots.label || label) {
      let $asterix

      if (required) {
        $asterix = createElement('span', {
          class: 'ui-field__label-required'
        }, ' *')
      }

      const labelContent = $slots.label || [label]

      $label = createElement('label', {
        class: 'ui-field__label',
        attrs: {
          for: name
        }
      }, [...labelContent, $asterix])
    }

    // final rendering
    return createElement('div', {
      class: ['ui-field', { inline, light, error: invalid, valid, disabled }]
    }, [
      $label,
      this.$slots.default,
      $help
    ])
  }
}
</script>

<style lang="scss">
@import '../style/main';

$input-height: 2rem;

.ui-field {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;

  &__label {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 5px;
    color: $text-default-color;
    transition: color 0.1s ease;

    .ui-icon {
      cursor: pointer;
    }
  }

  &__label-required {
    color: $palette-red;
  }

  &__content {
    display: block;
    position: relative;

    @include fields {
      display: block;
      cursor: auto;
      background-color: $white;
      box-sizing: border-box;
      outline: none;
      border: none;
      border-radius: $input-height / 2;

      padding: ($input-height / 4) ($input-height / 2);
      width: 100%;
      color: $text-default-color;
      font-weight: normal;
      font-size: 16px;
      line-height: normal;

      border: 1px $blue-grey-100 solid;

      &.hidden {
        color: transparent;
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.24), 0 0px 8px rgba(0, 0, 0, 0.12);
      }
    }

    input[type='text'],
    input[type='password'],
    input[type='number'],
    input[type='email'] {
      height: $input-height;
    }

    textarea {
      resize: vertical;
      overflow-x: hidden;
      padding: ($input-height / 4) ($input-height / 2);

      &.no-resize {
        resize: none;
        overflow: auto;
      }
    }

    input.icon-right,
    textarea.icon-right {
      padding-right: $gutter-md;
    }
    input.icon-left,
    textarea.icon-left {
      padding-left: $gutter-md;
    }

    &-icon-right,
    &-icon-left {
      position: absolute;
      top: $gutter-sm;
      cursor: pointer;
      color: transparentize($dark, .7);

      &:before {
        font-size: 1.2rem;
        line-height: 1.2em;
      }

      transition: color ease .3s;

      &:hover {
        color: $dark;
      }
    }

    &-icon-right {
      right: $gutter-sm;
    }

    &-icon-left {
      left: $gutter-sm;
    }
  }

  &__help {
    color: $grey-200;
    font-size: .9em;
    padding-top: 3px;
    line-height: 1em;
  }

  &.inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: $gutter-sm;

    .ui-field__label {
      width: auto;
      white-space: nowrap;
    }

    .ui-field__content {
      flex-grow: 1;
    }
  }

  &.light {
    .ui-field__label {
      color: $white;
    }
    .ui-field__content {
      @include fields {
        background: $dark;
        color: $white;
      }
    }
  }

  &.disabled {
    .ui-field__content {
      @include fields {
        background: $grey-500;
        color: $grey-300;
      }
    }
  }

  &.error {
    .ui-field__content {
      @include fields {
        box-shadow: 0 0 2px 0 $palette-red inset;
        border: 1px $palette-red solid;
        color: $palette-red;
      }
    }
  }

  &.valid {
    .ui-field__content {
      @include fields {
        box-shadow: 0 0 2px 0 $palette-green inset;
        border: 1px $palette-green solid;
      }
    }
  }
}
</style>
