
const FormFieldMixin = {
  name: 'UiFormField',

  props: {
    value: {
      type: [String, Number],
      default: null
    },

    label: {
      type: String,
      default: null
    },

    help: {
      type: String,
      default: null
    },

    light: Boolean,

    placeholder: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: 'md'
    },

    name: {
      type: String,
      required: true
    },

    readonly: Boolean,

    disabled: Boolean,

    required: Boolean,

    inline: Boolean,

    invalid: Boolean,

    valid: Boolean
  },

  data () {
    return {
      focused: false
    }
  },

  computed: {
    attributes () {
      return {
        name: this.name,
        id: this.name,
        placeholder: this.placeholder,
        disabled: this.disabled,
        readonly: this.readonly,
        required: this.required,
        invalid: this.invalid,
        valid: this.valid
      }
    }
  },

  methods: {
    update (value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },

    onFocus () {
      this.focused = true
      this.$emit('focus')
    },
    onBlur () {
      this.focused = false
      this.$emit('blur')
    }
  }
}

export default FormFieldMixin
