<script>
import UiIcon from '../UiIcon'

/**
 * The only true UiButton.
 * @displayName UiButton
 */
export default {
  name: 'UiButton',

  components: {
    UiIcon
  },

  props: {
    /**
     * The type of the design for the UiButton
     * `default, primary, secondary, success, alert, primary-outline,
     *  secondary-outline, light-outline`
     */
    type: {
      type: String,
      default: 'default',
      validator: v => [
        'default',
        'primary',
        'secondary',
        'success',
        'alert',
        'primary-outline',
        'secondary-outline',
        'light-outline'
      ].indexOf(v) !== -1
    },

    /**
     * Show a lighten UiButton
     */
    light: Boolean,

    /**
     * Show a darken UiButton
     */
    dark: Boolean,

    /**
     * vue-router herited prop
     */
    to: {
      type: [String, Object],
      default: null
    },

    /**
     * vue-router herited prop
     */
    exact: Boolean,

    /**
     * If the UiButton is disabled
     */
    disabled: Boolean,

    /**
     * href attribute, in this case the UiButton become a `a` tag
     */
    href: {
      type: String,
      default: null
    },

    /**
     * show a loader status icon
     */
    loading: Boolean,

    /**
     * the size of the UiButton `sm, md`
     */
    size: {
      type: String,
      default: 'md'
    },

    /**
     * show an icon in the UiButton
     */
    icon: {
      type: String,
      default: null
    },

    /**
     * force the content of the UiButton to the left
     */
    alignLeft: Boolean,

    /**
     * If the content of the UiButton is multi line
     */
    multiline: Boolean,

    /**
     * for links (with href prop) add securised thing for `target="_blank"`
     */
    blank: Boolean
  },

  render (createElement) {
    const attrs = {
      class: [
        'ui-button',
        [
          this.type,
          { 'ui-button-light': this.light },
          { 'ui-button-dark': this.dark },
          { 'align-left': this.alignLeft },
          { multiline: this.multiline },
          `ui-button-${this.size}`
        ]
      ],
      attrs: {
        disabled: this.disabled,
        href: this.href,
        target: this.blank ? '_blank' : null,
        rel: this.blank ? 'noopener noreferrer' : null
      },
      on: !this.disabled && this.$listeners
    }
    let tag = 'button'

    if (this.href) {
      tag = 'a'
    } else if (this.$router && this.to) {
      this.$options.props = {
        ...(
          this.$options.components.RouterLink ||
          this.$options.components['router-link']
        ),
        ...this.$options.props
      }

      tag = 'router-link'
      attrs.props = this.$props

      delete attrs.props.type
      delete attrs.attrs.type
      delete attrs.props.href
      delete attrs.attrs.href
    }

    const slotsLeft = []

    if (this.loading || this.icon) {
      slotsLeft.push(createElement(UiIcon, {
        class: [{ 'ui-icon-spin': this.loading }, 'force-left'],
        attrs: {
          name: this.icon || 'loading'
        }
      }))
    }

    return createElement(tag, attrs, [...slotsLeft, ...this.$slots.default])
  }
}
</script>

<style lang="scss">
@import '../style/main';

.ui-button {
  $btn-height: 2.5rem;
  $btn-height-sm: 2rem;
  $btn-border-size: 0.1rem;
  $btn-min-width: 6rem;

  @mixin hover-active($color) {
    &:hover {
      background-color: lighten($color, 5%);
    }

    &:active,
    &.active,
    &.router-link-active {
      background-color: lighten($color, 10%);
    }
  }

  @mixin hover-active-outline($color) {
    &:hover {
      border: $btn-border-size solid lighten($color, 5%);
    }

    &:active,
    &.active,
    &.router-link-active {
      border: $btn-border-size solid lighten($color, 10%);
    }
  }

  @mixin btn-colored($color) {
    background-color: $color;
    color: $white;
    fill: $white;

    @include hover-active($color);

    &:disabled {
      background-color: lighten($color, 20%);
      @include hover-active(lighten($color, 15%));
    }
  }

  @mixin btn-outlined($color) {
    border: $btn-border-size solid $color;
    color: $color;
    fill: $color;
    height: $btn-height - $btn-border-size * 2;
    line-height: $btn-height - $btn-border-size * 2;

    &.ui-button-sm {
      height: $btn-height-sm - $btn-border-size * 2;
      line-height: $btn-height-sm - $btn-border-size * 2;
    }

    @include hover-active-outline($color);

    &:disabled {
      border: $btn-border-size solid lighten($color, 20%);
      @include hover-active-outline(lighten($color, 15%));
    }
  }

  background: 0 0;
  border: none;
  border-radius: $btn-height / 2;
  color: $dark;

  display: inline-block;
  position: relative;
  padding: 0 $btn-height/2;
  height: $btn-height;
  min-width: $btn-min-width;
  box-sizing: content-box;

  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  line-height: $btn-height;
  letter-spacing: 0;
  overflow: hidden;

  white-space: nowrap;

  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;

  @include text-ellipsis();

  $color: transparentize($white, .95);
  @include hover-active(transparentize($dark, .90));

  &:disabled {
    background-color: lighten($color, 20%);
    @include hover-active(lighten($color, 15%));
    cursor: auto;
  }

  &.ui-button-light {
    $color: transparentize($white, .95);
    @include hover-active($color);
    color: $white;
    fill: $white;

    &:disabled {
      background-color: lighten($color, 20%);
      @include hover-active(lighten($color, 15%));
    }
  }

  &.ui-button-dark {
    $color: transparentize(#000, .75);
    background-color: $color;
    color: $white;
    fill: $white;

    @include hover-active($color);

    &:disabled {
      background-color: lighten($color, 20%);
      @include hover-active(lighten($color, 15%));
    }
  }

  &.primary {
    @include btn-colored($primary-color)
  }

  &.primary-outline {
    @include btn-outlined($primary-color)
  }

  &.secondary {
    @include btn-colored($secondary-color)
  }

  &.secondary-outline {
    @include btn-outlined($secondary-color)
  }

  &.light-outline {
    @include btn-outlined($white)
  }

  &.success {
    @include btn-colored($palette-green)
  }

  &.alert {
    @include btn-colored($palette-red)
  }

  .ui-icon.force-left {
    margin-left: -0.4em;
    margin-right: 0.4em;
  }

  &.ui-button-sm {
    padding: 0 $gutter-sm;
    border-radius: $btn-height-sm / 2;
    height: $btn-height-sm;
    line-height: $btn-height-sm;

    font-size: 12px;
  }

  &.align-left {
    text-align: left;
  }

  &.multiline {
    height: auto;
    white-space: normal;
    line-height: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
</style>

<docs>
Buttons types:
```
  <UiButton>Default</UiButton>

  <UiButton type="primary">Primary</UiButton>

  <UiButton type="secondary">Secondary</UiButton>

  <UiButton type="success">Success</UiButton>

  <UiButton type="alert">Alert</UiButton>

  <UiButton type="primary-outline">Primary Outline</UiButton>

  <UiButton type="secondary-outline">Secondary Outline</UiButton>

  <UiButton type="light-outline">Light Outline</UiButton>
```

UiButton Size
```
<UiButton type="primary" size="md">Default Size</UiButton>
<UiButton type="primary" size="sm">Small Size</UiButton>
```

With Loader
```
<UiButton type="primary" loading>Loading</UiButton>
```

Disabled UiButton
```
<UiButton type="primary" disabled>Disabled UiButton</UiButton>
```
</docs>
