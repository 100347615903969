import Model, { converterGenerator } from './Model'

class Clan extends Model {
  id
  name
  displayName
  displayNameFR
  description
  color
  imagePath
  chieftain
  totalMembers
  totalPoints

  constructor (data = {}) {
    super(data)

    this.id = data.id || null
    this.name = data.name || null
    this.displayName = data.displayName || null
    this.displayNameFR = data.displayNameFR || null
    this.description = data.description || null
    this.color = data.color || null
    this.imagePath = data.imagePath || null
    this.chieftain = data.chieftain || null
    this.totalMembers = data.totalMembers || 0
    this.totalPoints = data.totalPoints || 0
  }
}

export const clanConverter = converterGenerator(Clan, data => {
  return {
    id: data.id,
    name: data.name,
    displayName: data.displayName,
    displayNameFR: data.displayNameFR,
    description: data.description,
    color: data.color,
    imagePath: data.imagePath,
    chieftain: data.chieftain,
    totalMembers: data.totalMembers,
    totalPoints: data.totalPoints
  }
})

export default Clan
