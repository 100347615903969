import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import user from './modules/user'
import profile from './modules/profile'
import platform from './modules/platform'

Vue.use(Vuex)

const storeKey = 'chatondearu.fr'
const vuexPersist = new VuexPersistence({
  key: storeKey,
  storage: window.localStorage,
  supportCircular: false,
  reducer: (state) => ({
    user: state.user
  })
})

export default new Vuex.Store({
  modules: {
    user,
    profile,
    platform
  },
  plugins: [
    vuexPersist.plugin
  ]
})
