<i18n>
{
  "en": {
    "expand_text_more": "See more"
  },
  "fr": {
    "expand_text_more": "Voir plus"
  }
}
</i18n>

<script>
export default {
  name: 'UiExpand',

  props: {
    text: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      expanded: false
    }
  },

  computed: {
    reducedText () {
      const { text } = this
      if (
        text && this.needCut() && !this.expanded
      ) {
        return text.slice(0, 200).trim().concat(text.slice(200).split(/\s/)[0] + '…')
      } else {
        return text
      }
    }
  },

  methods: {
    needCut () {
      const text = document.createElement('span')
      document.body.appendChild(text)

      text.style.width = '348px'
      text.style.position = 'absolute'
      text.innerHTML = this.text
      const height = text.clientHeight

      document.body.removeChild(text)
      return Math.round(height / 19) > 5
    }
  }
}
</script>

<template>
  <div>
    <p
      class="mrg-top"
    >
      {{ reducedText }}
    </p>
    <UiButton
      class="primary-outline"
      :class="{hidden: !needCut() || expanded}"
      size="sm"
      @click="expanded = true"
    >
      {{ $t('expand_text_more') }}
    </UiButton>
  </div>
</template>

<style lang="scss">
  .hidden {
    display: none;
  }
</style>
