<script>
import UiIcon from '../UiIcon'

export default {
  name: 'UiIconButton',

  components: {
    UiIcon
  },

  props: {
    type: {
      type: String,
      default: 'default',
      validator: v => ['default', 'primary', 'secondary'].indexOf(v) !== -1
    },

    light: Boolean,

    to: {
      type: [String, Object],
      default: null
    },

    exact: Boolean,

    disabled: Boolean,

    href: {
      type: String,
      default: null
    },

    icon: {
      type: String,
      default: 'heart'
    },

    size: {
      type: String,
      default: 'md'
    },

    iconSize: {
      type: String,
      default: null
    },

    color: {
      type: String,
      default: null
    }
  },

  render (createElement) {
    const attrs = {
      class: [
        'ui-icon-button',
        [
          this.type,
          { 'button-light': this.light },
          `button-${this.size}`
        ]
      ],
      attrs: {
        disabled: this.disabled,
        href: this.href
      },
      on: this.$listeners
    }
    let tag = 'button'

    if (this.href) {
      tag = 'a'
    } else if (this.$router && this.to) {
      this.$options.props = {
        ...(
          this.$options.components.RouterLink ||
          this.$options.components['router-link']
        ),
        ...this.$options.props
      }
      tag = 'router-link'
      attrs.props = this.$props
      delete attrs.props.type
      delete attrs.attrs.type
      delete attrs.props.href
      delete attrs.attrs.href
    }

    let icon = null
    if (this.icon) {
      icon = createElement(UiIcon, {
        attrs: {
          name: this.icon,
          size: this.iconSize,
          color: this.light ? 'white' : this.color
        }
      })
    }

    return createElement(tag, attrs, [icon || this.$slots.default])
  }
}
</script>

<style lang="scss">
@import '../style/variables';

@mixin hover-active($color) {
  &:hover {
    background-color: lighten($color, 5%);
  }

  &:active,
  &.active,
  &.router-link-active {
    background-color: lighten($color, 10%);
  }
}

$icon-button-size: 38px;
$icon-button-size-sm: 28px;
$icon-button-size-lg: 48px;

$icon-button-padding: 0;

.ui-icon-button {
  background: 0 0;
  border: none;
  border-radius: 50%;
  color: $dark;

  display: inline-block;
  position: relative;
  margin: 0;
  padding: $icon-button-padding;
  box-sizing: content-box;

  $icon-button-calculated-size: $icon-button-size - $icon-button-padding * 2;
  height: $icon-button-calculated-size;
  width: $icon-button-calculated-size;

  text-align: center;
  vertical-align: middle;
  line-height: $icon-button-calculated-size;
  overflow: hidden;

  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;

  .ui-icon {
    line-height: $icon-button-calculated-size;
    padding-left: 0;
  }

  @include hover-active(transparentize($dark, .90));

  &.button-light {
    @include hover-active(transparentize($white, .75));
    color: $white;
  }

  &.primary {
    background-color: $primary-color;
    color: $white;

    @include hover-active($primary-color);
  }

  &.secondary {
    background-color: $secondary-color;
    color: $white;

    @include hover-active($secondary-color);
  }

  &:disabled {
    background-color: $blue-grey-50;
    color: $blue-grey-300;
    cursor: not-allowed;
  }

  &.button-sm {
    $icon-button-calculated-size-sm: $icon-button-size-sm - $icon-button-padding * 2;
    padding: $icon-button-padding;
    height: $icon-button-calculated-size-sm;
    width: $icon-button-calculated-size-sm;
    margin: 1px;
    line-height: $icon-button-calculated-size-sm;

    & .ui-icon {
      padding-left: 0;

      &:not(.ui-icon-sm):before {
        margin-top: -$icon-button-padding / 2;
        margin-left:  -$icon-button-padding / 2;
        margin-right: 0;
      }
    }
  }

  &.button-lg {
    $icon-button-calculated-size-lg: $icon-button-size-lg - $icon-button-padding * 2;
    padding: $icon-button-padding;
    height: $icon-button-calculated-size-lg;
    width: $icon-button-calculated-size-lg;
    margin: 1px;
    line-height: $icon-button-calculated-size-lg;

    & .ui-icon {
      padding-left: 0;

      &:not(.ui-icon-lg):before {
        margin-top: -$icon-button-padding / 2;
        margin-left:  -$icon-button-padding / 2;
        margin-right: 0;
      }
    }
  }

  & > * {
    border-radius: 0 !important;
  }
}
</style>
