import noop from 'lodash/noop'
import remove from 'lodash/remove'
import reverse from 'lodash/reverse'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
import anime from 'animejs'

import UiSnackBar from './UiSnackBar'

function install (Vue, options) {
  const SnackBarController = Vue.extend(UiSnackBar)
  const defaultSnackOptions = {
    duration: 3500,
    message: '',
    type: 'default',
    isRetry: false,
    onStart: noop,
    onRetry: noop,
    onFinish: noop,
    position: 'bottom right'
  }

  const Snack = {
    state: {
      snacks: [],
      stackedSnacks: [],
      isStarted: false,
      snackTimer: null,
      parent: document.body
    },

    push (snackOptions) {
      this.state.snacks.push(snackOptions)
      if (!this.state.isStarted) {
        this.start()
      }
    },

    start () {
      const snacks = this.state.snacks
      if (snacks.length) {
        // select first snack to show
        this.state.isStarted = true
        this.next()
      }
    },

    pause () {
      clearTimeout(this.state.snackTimer)
      this.state.isStarted = false
    },

    reset () {
      this.state.snacks = []
    },

    stop () {
      this.pause()
      this.reset()
    },

    alignSnacks () {
      const snackPositions = groupBy(
        this.state.stackedSnacks,
        s => s.definition.position
      )

      forEach(snackPositions, (snacks, position) => {
        let lastPosition = 0
        let stackPosition = 'bottom'

        position = position.replace(' ', '.')

        if (position.indexOf('top') !== -1) {
          stackPosition = 'top'
        }

        const timeline = anime.timeline()
        timeline.pause()

        forEach(reverse(snacks), snack => {
          const element = snack.vm.$el

          timeline.add({
            targets: element,
            [stackPosition]: lastPosition,
            duration: 200,
            offset: 0
          })

          // 10px of margin between two snackbar
          lastPosition = element.offsetHeight + 10
        })

        timeline.play()
      })
    },

    next () {
      const snack = this.state.snacks[0] || null

      if (snack) {
        this.state.snacks.shift()

        if (this.state.isStarted) {
          // set next snack to show
          this.state.snackTimer = setTimeout(() => { this.next() }, 500)
        }
      } else {
        // no more snack to show this is the end
        this.pause()
        return
      }

      const div = document.createElement('div')

      if (options && options.parent) {
        options.parent.appendChild(div)
      } else {
        document.body.appendChild(div)
      }

      const snackDefinition = {
        ...defaultSnackOptions,
        ...snack
      }
      let vm = new SnackBarController({
        propsData: snackDefinition
      })

      vm.$mount(div)
      vm.$on('destroy', () => {
        remove(this.state.stackedSnacks, s => s.vm._uid === vm._uid)

        anime({
          targets: vm.$el,
          opacity: [1, 0],
          translateX: [0, -20],
          duration: 1700,
          complete: () => {
            vm.$destroy()
            vm.$el.remove()
            vm = null
          }
        })

        this.alignSnacks()
      })

      this.state.stackedSnacks.push({
        definition: snackDefinition,
        vm
      })

      this.alignSnacks()
    }
  }

  Vue.prototype.$snack = Snack
}

export default {
  install
}
