<script>
import UiIcon from '../UiIcon'

export default {
  name: 'UiLoader',

  components: {
    UiIcon
  }
}
</script>

<template>
  <div class="hover-loading">
    <UiIcon
      class="ui-icon-spin"
      name="loading"
      color="#fff"
      size="lg"
    />
  </div>
</template>

<style lang="scss">
@import '../style/variables';

.hover-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  background-color: transparentize($pink, .3);

  display: flex;
  justify-content: center;
  align-items: center;

  &.relatif {
    position: absolute;
  }
}
</style>
