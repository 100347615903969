<i18n>
{
  "en-US": {
    "cancel": "cancel",
    "ok": "ok"
  },
  "fr-FR": {
    "cancel": "annuler",
    "ok": "oui"
  }
}
</i18n>

<script>
import { closableMixin, stopScrollPropagationMixin } from '@/utils/mixins'
import UiButton from '../UiButton'

export default {
  name: 'UiDialog',

  components: {
    UiButton
  },

  mixins: [
    closableMixin,
    stopScrollPropagationMixin
  ],

  props: {
    noOverlay: Boolean,

    confirm: Boolean,

    title: {
      type: String,
      default: null
    },

    confirmTextBtn: {
      type: String,
      default: null
    }
  },

  methods: {
    onCancel () {
      this.$emit('cancel')
      this.close()
    },
    onConfirm () {
      this.$emit('confirm')
      this.close()
    }
  }
}
</script>

<template>
  <div class="ui-dialog">
    <div
      v-if="!noOverlay && !closed"
      class="dialog__overlay ui-dialog"
      @click="close"
    />

    <transition name="slide-fade">
      <div
        v-if="!closed"
        class="dialog"
      >
        <div class="dialog__header">
          <CloseButton v-if="!confirm" />

          <h2 v-if="$slots.title || title">
            <slot name="title">
              <span v-emoji="title" />
            </slot>
          </h2>
        </div>

        <div
          v-if="$slots.default"
          class="dialog__content"
        >
          <slot />
        </div>

        <div
          v-if="confirm"
          class="dialog__confirm-actions text-right"
        >
          <UiButton
            type="secondary"
            @click="onCancel"
          >
            {{ $t('cancel') }}
          </UiButton>
          <UiButton
            type="primary"
            @click="onConfirm"
          >
            {{ confirmTextBtn || $t('ok') }}
          </UiButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import '../style/main';
@import '../style/transitions';

.ui-dialog {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  pointer-events: none !important;
  z-index: 10;

  text-align: initial;

  > .dialog__overlay {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
    pointer-events: auto;
    transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);

    background-color: transparentize($dark, 0.65%);
  }

  > .dialog {
    @include card(5);
    @include text-shadow(0);

    position: relative;
    pointer-events: auto;
    border-radius: $border-radius-md;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.3s ease-in-out;
    width: 100%;
    max-height: calc(100vh - 64px);
    z-index: 13;

    background-color: $white;
    color: $text-default-color;
    margin-top: 46px;
    margin-right: $gutter-sm;
    margin-left: $gutter-sm;

    @media screen and (min-width: $screen-xs) {
      margin: 24px;
      max-width: 500px;
      min-width: $screen-xs;
    }

    .dialog__header {
      h2 {
        padding-left: $gutter;
        padding-top: $gutter;
        padding-bottom: $gutter;
        margin-right: 50px;
        white-space: normal;
      }

      .mx-closable-close-button {
        float: right;
        padding-top: $gutter-sm;
        padding-right: $gutter-sm;
      }
    }

    .dialog__content {
      height: 100%;
      left: 0;
      top: 0;
      transition: 0.3s ease-in-out;
      white-space: normal;

      padding: $gutter;
    }

    .dialog__confirm-actions {
      text-align: right;
      padding: $gutter;
      > * {
        margin-left: $gutter;
      }
    }
  }
}
</style>
