<script>
import { clickOutsideMixin } from '@/utils/mixins'

export default {
  name: 'UiMenu',

  mixins: [
    clickOutsideMixin
  ],

  props: {
    position: {
      type: String,
      default: 'left'
    },

    fit: Boolean,

    condensed: Boolean,

    transition: {
      type: String,
      default: 'slide-fade'
    }
  },

  data () {
    return {
      isOpen: false
    }
  },

  methods: {
    toggleMenu () {
      const { isOpen, openMenu, closeMenu } = this
      if (isOpen) {
        closeMenu()
      } else {
        openMenu()
      }
    },

    openMenu () {
      this.isOpen = true
    },

    closeMenu () {
      this.isOpen = false
    }
  }
}
</script>

<template>
  <span
    v-click-outside="closeMenu"
    class="ui-menu"
  >
    <span @click="toggleMenu">
      <slot />
    </span>
    <transition :name="transition">
      <div
        v-show="isOpen"
        class="ui-menu__content"
        :class="[position, {fit, condensed}]"
      >
        <slot
          name="content"
          :closeMenu="closeMenu"
        />
      </div>
    </transition>
  </span>
</template>

<style lang="scss">
@import '../style/main';

.ui-menu {
  position: relative;
  display: inline-block;

  &-separator {
    width: auto;
    margin: 0 $gutter;
    border-bottom: 1px solid $grey-100;
  }

  &__content {
    position: absolute;
    z-index: 1;
    min-width: 150px;
    max-width: 300px;
    top: 100%;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 3px;
    margin-top: $gutter-sm;

    @include card(2);

    &.condensed {
      bottom: 80%;
      & * {
        font-size: 0.7em !important;
      }
    }

    & .ui-button {
      text-align: left;
      border-radius: 0;
    }

    &.fit {
      width: auto;
    }

    &.top {
      top: inherit;
      bottom: 100%;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}
</style>
